@font-face {
  font-family: "HK Grotesk";
  src: url("src/hk-grotesk/HKGrotesk-Light.woff2") format("woff2"),
    url("src/hk-grotesk/HKGrotesk-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "HK Grotesk";
  src: url("src/hk-grotesk/HKGrotesk-LightItalic.woff2") format("woff2"),
    url("src/hk-grotesk/HKGrotesk-LightItalic.woff") format("woff");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "HK Grotesk";
  src: url("src/hk-grotesk/HKGrotesk-Regular.woff2") format("woff2"),
    url("src/hk-grotesk/HKGrotesk-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "HK Grotesk";
  src: url("src/hk-grotesk/HKGrotesk-Italic.woff2") format("woff2"),
    url("src/hk-grotesk/HKGrotesk-Italic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "HK Grotesk";
  src: url("src/hk-grotesk/HKGrotesk-Medium.woff2") format("woff2"),
    url("src/hk-grotesk/HKGrotesk-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "HK Grotesk";
  src: url("src/hk-grotesk/HKGrotesk-MediumItalic.woff2") format("woff2"),
    url("src/hk-grotesk/HKGrotesk-MediumItalic.woff") format("woff");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "HK Grotesk";
  src: url("src/hk-grotesk/HKGrotesk-SemiBold.woff2") format("woff2"),
    url("src/hk-grotesk/HKGrotesk-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "HK Grotesk";
  src: url("src/hk-grotesk/HKGrotesk-SemiBoldItalic.woff2") format("woff2"),
    url("src/hk-grotesk/HKGrotesk-SemiBoldItalic.woff") format("woff");
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "HK Grotesk";
  src: url("src/hk-grotesk/HKGrotesk-Bold.woff2") format("woff2"),
    url("src/hk-grotesk/HKGrotesk-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "HK Grotesk";
  src: url("src/hk-grotesk/HKGrotesk-BoldItalic.woff2") format("woff2"),
    url("src/hk-grotesk/HKGrotesk-BoldItalic.woff") format("woff");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "HK Grotesk";
  src: url("src/hk-grotesk/HKGrotesk-ExtraBold.woff2") format("woff2"),
    url("src/hk-grotesk/HKGrotesk-ExtraBold.woff") format("woff");
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "HK Grotesk";
  src: url("src/hk-grotesk/HKGrotesk-Black.woff2") format("woff2"),
    url("src/hk-grotesk/HKGrotesk-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
